import { CSSProperties, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import styles from './index.module.css'

interface IFadeInProps {
  duration?: number
  zIndex?: number
}

export type ActionRef = {
  fadeIn: () => void
}

const interval = 10

/**
 * 原地渐入遮罩
 */
const PlaceFadeInMask = forwardRef<ActionRef, IFadeInProps>(function ({ duration = 1000, zIndex = 10 }, ref) {
  const [opacity, setOpacity] = useState<number>(1)
  const timerRef = useRef<number | null>(null)

  const fadeIn = () => {
    setOpacity(1)
    clearInterval(timerRef.current as number)
    timerRef.current = window.setInterval(() => {
      setOpacity(prev => {
        if (prev < 0) {
          clearInterval(timerRef.current as number)
          return 0
        }
        return prev - interval / duration
      })
    }, interval)
  }

  useImperativeHandle(ref, () => ({
    fadeIn,
  }))

  const innerStyle: CSSProperties = {
    zIndex,
    opacity,
  }
  if (opacity <= 0) return null
  return <div className={styles['place-fade-in-mask']} style={innerStyle}></div>
})

export default PlaceFadeInMask
